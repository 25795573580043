import * as React from 'react'
import { Link } from 'gatsby'
import { IconSizeGuidePDF } from '../Icons'

const FindYourSize = ({ category, onClick }) => {
  let guideUrl = '',
    guideBlogUrl = ''
  switch (category) {
    case 'Watch':
    case 'Bracelet':
      guideUrl = '/pdfs/watch-size-guide.pdf'
      break
    case 'Necklace':
      guideUrl = '/pdfs/Necklace-Measuring-Guide.pdf'
      break
    case 'Watch Strap':
    case 'Watch Band':
      guideUrl = '/pdfs/watchband-measuring-guide.pdf'
      break
    case 'Ring':
      guideUrl = '/pdfs/gns_ring_measurement.pdf'
      break
    default:
      return null
  }
  const onClickHandler = React.useCallback(
    e => {
      e.preventDefault()
      onClick(guideUrl)
    },
    [onClick, guideUrl]
  )
  return (
    <section className="md:col-span-4 col-span-2 py-2 gap-2 flex flex-row items-end">
      <a
        href={guideUrl}
        target={'_blank'}
        rel="noopener"
        className=" hover:text-red-600 "
        onClick={onClickHandler}
      >
        <IconSizeGuidePDF />
      </a>
      <Link
        to={guideBlogUrl}
        className=" w-full hover:text-red-600 uppercase "
        onClick={onClickHandler}
      >
        {category} SIZE GUIDE
      </Link>
    </section>
  )
}

export default FindYourSize
