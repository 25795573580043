import * as React from 'react'
import { useState } from 'react'
import PriceCard from './PriceCard'
import EstimatedTotalPrice from './EstimatedTotalPrice'
import AddToCartBTN from '../AddToCartBtn'
import EstimatedDeliveryDate from './EstimatedDeliveryDate'
import AskForAvailability from './AskForAvailability'
import CurrentlyUnavailable from './CurrentlyUnavailable'
import isBankWireOnly from '../../helpers/product/isBankWireOnly'
import AskForPrice from './AskForPrice'

const InStock = ({
  info,
  isInCart,
  addProductToCart,
  isMakingAnOffer,
  openMakeAnOffer,
  openAskAQuestion,
  cartProduct,
  onAffirmDetailsClick,
}) => {
  return (
    <div className="mx-auto grid w-full max-w-[500px] grid-cols-2 gap-3 lg:mx-0 ">
      <PriceCard product={cartProduct || info} onAffirmDetailsClick={onAffirmDetailsClick} />
      <AddToCartBTN onClick={addProductToCart} isInCart={isInCart} />
      {!isBankWireOnly(info) && (
        <button
          className={
            'col-span-2 xxs:col-span-1 text-sm sm:text-base relative rounded-md border-2 border-green-700 py-3 font-bold text-green-700 duration-200 hover:bg-green-700 hover:text-white ' +
            (isMakingAnOffer ? ' active-btn ' : '')
          }
          onClick={openMakeAnOffer}
        >
          MAKE AN OFFER
        </button>
      )}
      <button
        className="col-span-2 xxs:col-span-1 text-sm sm:text-base relative rounded-md border-2 border-gray-500 py-3 font-bold text-gray-500 duration-200 hover:bg-gray-500 hover:text-white"
        onClick={openAskAQuestion}
      >
        ASK A QUESTION
      </button>
      <EstimatedDeliveryDate className="col-span-2 flex flex-row gap-2 text-sm md:text-base" />
    </div>
  )
}

const ProductBuyingOptions = ({
  info,
  isInCart,
  addProductToCart,
  isMakingAnOffer,
  openMakeAnOffer,
  openAskAQuestion,
  openChatHandler,
  cartProduct,
  onAffirmDetailsClick,
}) => {
  let component = null
  if (info.on_hold === 1) {
    component = (
      <AskForAvailability
        product={info}
        openAskAQuestion={openAskAQuestion}
        isMakingAnOffer={isMakingAnOffer}
        openChatHandler={openChatHandler}
        openMakeAnOffer={openMakeAnOffer}
      />
    )
  } else if (info.ask_for_price === 1) {
    component = (
      <AskForPrice
        product={info}
        openAskAQuestion={openAskAQuestion}
        isMakingAnOffer={isMakingAnOffer}
        openMakeAnOffer={openMakeAnOffer}
      />
    )
  } else if (info.qty <= 0) {
    component = <CurrentlyUnavailable product={info} />
  } else {
    component = (
      <InStock
        info={info}
        isInCart={isInCart}
        addProductToCart={addProductToCart}
        isMakingAnOffer={isMakingAnOffer}
        openMakeAnOffer={openMakeAnOffer}
        openAskAQuestion={openAskAQuestion}
        openChatHandler={openChatHandler}
        cartProduct={cartProduct}
        onAffirmDetailsClick={onAffirmDetailsClick}
      />
    )
  }
  return (
    <div className="w-full flex flex-col justify-center items-center md:items-start">
      {component}
    </div>
  )
}

export default ProductBuyingOptions
