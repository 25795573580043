import * as React from 'react'
import { USD } from '../../helpers/NumberHelper'
import PropTypes from 'prop-types'
import finalPrice from '../../helpers/product/finalPrice'
import { convertDateAgoFromNow } from '../../utils/dateAgo'

const CurrentlyUnavailable = ({ product }) => {
  return (
    <div className="space-y-2">
      <div className="my-5 w-full max-w-[500px] rounded-lg border-2 border-gray-300 p-4 text-center font-bold uppercase text-gray-500">
        currently unavailable
      </div>
      Sold <a title={`${product.stock_date}`}>{convertDateAgoFromNow(product.stock_date)}</a>.
      Asking price <b>{USD(finalPrice(product))}</b>
      <p>
        Do you own this or a similar item and would like to <b>sell to us?</b>
      </p>
      {/* eslint-disable-next-line */}
      <a
        href={
          'https://sellusyourjewelry.com/what-are-you-looking-to-sell-2/?utm_source=referral&utm_medium=grayandsons&utm_campaign=we_buy'
        }
        rel="noopener"
        target={'_blank'}
        className="w-full block text-center max-w-[500px] rounded-lg hover:bg-[#b78d4a] py-4 font-bold text-white bg-[#ceaa72] duration-200"
      >
        SELL ONE LIKE THIS
      </a>
    </div>
  )
}
CurrentlyUnavailable.propTypes = {
  product: PropTypes.object.isRequired,
}

export default CurrentlyUnavailable
