import * as React from 'react'
import { useState, useCallback, useEffect } from 'react'
import { graphql } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { ProductImage } from '../components/CategoryImage'
import { getLabel, getValue } from '../helpers/StringHelpers'
import { USD } from '../helpers/NumberHelper'
import { showPopup, openChat } from '../state/popupForms'
import { addProduct } from '../state/cart'
import FindYourSize from '../components/products/FindYourSize'
import {
  Icon10Days,
  Icon2yrWarranty,
  IconArrowForward,
  IconAuthentic,
  IconYearPolish,
  QuestionsChat,
  QuestionsLocation,
  QuestionsMail,
  QuestionsPhone,
} from '../components/Icons'
import { RichSideCard, ViktoriaSideCard, KeithSideCard } from '../components/ContactSideCards'
import ProductStickyHeaderCard from '../components/products/ProductStickyHeaderCard'
import ProductTitle from '../components/products/ProductTitle'
import finalPrice from '../helpers/product/finalPrice'
import IconExtLink from '../components/IconExtLink'
import { getProductDetails } from '../common/product'
import IconLoaders from '../icons/IconLoaders'
import ProductBuyingOptions from '../components/products/ProductBuyingOptions'
import { srcSizes } from '../common/gatsbyImageData'
import loadable from '@loadable/component'
import useMediaQuery from '../hooks/useMediaQuery'
import TailwindScreens from '../helpers/tailwind'
import DotSlider from '../components/sliders/DotSlider'
import { TrackEvent } from '../helpers/TrackingHelper'
import YouTube, { autoplayYouTubeVideoUrl } from '../components/homepage/YouTube'
import RecommendedProductsSlider from '../components/sliders/RecommendedProductsSlider'
import WhatWeDo from '../components/WhatWeDo'
const WhyGnS = loadable(() => import('../components/WhyGnS'))
const FAQComponent = loadable(() => import('../components/layout/FAQComponent'))
const SizeGuideComponent = loadable(() => import('../components/products/SizeGuideComponent'))
const AffirmFinancing = loadable(() => import('../components/products/AffirmFinancing'))
const ImageGalleryPopup = loadable(() => import('../components/popups/ImageGallery'))
const ImageGallerySlider = loadable(() => import('../components/sliders/ImageGallerySlider'))

const Label = ({ children, className }) => {
  return <p className={'text-gray-500 ' + className}>{children}</p>
}
Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  className: PropTypes.string,
}

const Value = ({ children, className }) => {
  return <b className={'px-2 text-left font-bold text-black ' + className}>{children}</b>
}
Value.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  className: PropTypes.string,
}

// const AskForAvailability = ({ product }) => {
//   return (
//     <div className="space-y-2">
//       <div className="my-5 w-full max-w-[500px] rounded-lg border-2 border-gray-300 p-4 ">
//         <p className="text-center font-bold uppercase text-gray-500">currently unavailable</p>
//       </div>
//       Sold <a title={`${product.stock_date}`}>{product.ago}</a>. Asking price{' '}
//       <b>{USD(finalPrice(product))}</b>
//       <p>
//         Do you own this or a similar item and would like to <b>sell to us?</b>
//       </p>
//       {/* eslint-disable-next-line */}
//       <a
//         href={
//           'https://sellusyourjewelry.com/what-are-you-looking-to-sell-2/?utm_source=referral&utm_medium=grayandsons&utm_campaign=we_buy'
//         }
//         rel="noopener"
//         target={'_blank'}
//         className="w-full block text-center max-w-[500px] rounded-lg bg-red-700 py-4 font-bold text-white hover:bg-red-800"
//       >
//         SELL ONE LIKE THIS
//       </a>
//     </div>
//   )
// }
// AskForAvailability.propTypes = {
//   product: PropTypes.object.isRequired,
// }

const ProductDetailsPage = ({ data }) => {
  const [haveQuestions, setHaveQuestions] = useState(false)
  const toggleHaveQuestions = () => setHaveQuestions(!haveQuestions)
  const isMd = useMediaQuery(TailwindScreens.md)

  const [showImage, setShowImage] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const info = data.item
  const product = data.item

  // redirectToCanonical(info.url + '/')

  const images = (info.media_gallery || [])
    .concat(info.certificates || [])
    .filter(img => img.gatsbyImageDataMock !== null)
  const [isMakingAnOffer, setIsMakingAnOffer] = useState(false)
  const [currentImage, setCurrentImage] = useState({ slide: images[0], type: 'image' })
  const isSellable = product.qty > 0

  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart)
  const keithSideCard = useSelector(state => state.popupForms.keithSideCard)
  const isInCart = true && !!cart?.products?.find(p => p.sku === product.sku)
  const cartProduct = {
    sku: product.sku,
    price: product.price,
    retail_price: product.retail_price,
    special_price: product.special_price,
    name: product.name,
    title: product.title,
    image: {
      url: product.image?.url,
      width: product.image?.width,
      height: product.image?.height,
      ext: product.image?.ext,
      name: product.image?.name,
    },
    url: product.url,
    brand: product.brand,
    model: product.model,
    model_no: product.model_no,
    color: product.dial_color,
    size: product.case_size_mm,
    qty: 1,
    avail_qty: product.qty,
    item_type: product.item_type,
    item_category: product.item_category,
    ask_for_price: product.ask_for_price,
    description: product.description,
    media_gallery: product.media_gallery.map(img => ({
      url: img.url,
      width: img.width,
      height: img.height,
      ext: img.ext,
      name: img.name,
    })),
    categories: product.categories.map(cat => cat.name),
  }
  const addProductToCart = useCallback(() => {
    TrackEvent('Added to Cart', {
      product: cartProduct,
      cart: [...cart.products, cartProduct],
      $value: cartProduct.price,
    })
    dispatch(addProduct(cartProduct))
  }, [dispatch, product, isInCart])
  const { attributes } = getProductDetails(product)
  const openChatHandler = useCallback(e => {
    e.preventDefault()
    dispatch(openChat())
  }, [])
  useEffect(() => {
    TrackEvent('Viewed Product', cartProduct)
  }, [cartProduct])

  const openAskAQuestion = useCallback(() => {
    dispatch(showPopup({ product, category: data.category, makeAnOffer: false }))
  }, [])
  const openMakeAnOffer = useCallback(() => {
    dispatch(showPopup({ product, category: data.category, makeAnOffer: true }))
    setIsMakingAnOffer(true)
  }, [])

  const onScroll = useCallback(() => {
    if (window.scrollY > 1000) {
      setShowPreview(true)
    } else {
      setShowPreview(false)
    }
  }, [setShowPreview])

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (window.tng) {
        window.tng.load()
      }
    }, 500)
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', onScroll, { passive: true })
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', onScroll)
      }
      clearTimeout(timeout)
    }
  }, [])

  const [sizeGuide, setSizeGuide] = useState('')
  const sizeGuideDetails = React.useRef(null)
  const onFindYourSizeClick = useCallback(
    pdf => {
      setSizeGuide(pdf)
    },
    [setSizeGuide]
  )
  useEffect(() => {
    if (!sizeGuideDetails.current) return
    // get page offset of the element
    const top = sizeGuideDetails.current.offsetTop - 30
    // scroll to the element
    window.scrollTo(0, top)
  }, [sizeGuide, sizeGuideDetails.current])
  const [viewFAQ, setViewFAQ] = useState('')
  const [viewAffirm, setViewAffirm] = useState('')
  const FAQRef = React.useRef(null)
  const AffirmRef = React.useRef(null)

  const onAffirmDetailsClick = useCallback(() => {
    setViewAffirm(true)
  }, [setViewAffirm])
  const onFAQClick = useCallback(() => {
    setViewFAQ(true)
  }, [setViewFAQ])
  useEffect(() => {
    if (!FAQRef.current) return
    // get page offset of the element
    const top = FAQRef.current.offsetTop - 30
    // scroll to the element
    window.scrollTo(0, top)
  }, [viewFAQ, FAQRef.current])
  useEffect(() => {
    if (!AffirmRef.current) return
    // get page offset of the element
    const top = AffirmRef.current.offsetTop - 30
    // scroll to the element
    window.scrollTo(0, top)
  }, [viewAffirm, AffirmRef.current])

  const [expandAccordion, setExpandAccordion] = useState(0)
  // turn accordion on/off
  const toggleAccordion = useCallback(
    index => {
      setExpandAccordion(index)
    },
    [expandAccordion]
  )

  return (
    <Layout
      className={'mx-auto flex flex-row'}
      breadcrumbs={data.category?.breadcrumbs}
      title={info.title}
      canonical={info.url + '/'}
      shop={true}
    >
      <Seo title={info.title} canonical={info.url + '/'} product={info} seo={info.seo} />

      <ProductStickyHeaderCard
        product={info}
        isInCart={isInCart}
        addProductToCart={addProductToCart}
        show={showPreview}
        category={data.category}
      />

      <aside className="hidden flex-grow 2xl:block" />
      <aside className="order-2 hidden w-[350px] flex-grow 2xl:block">
        <div className="sticky left-0 top-5 my-5 max-w-max space-y-5">
          {info.in_stock ? (
            keithSideCard ? (
              <KeithSideCard />
            ) : (
              <>
                <ViktoriaSideCard />
                <RichSideCard product={info} />
              </>
            )
          ) : (
            <div className="max-w-[350px]">
              <WhatWeDo orientation="vertical" iconColor="text-gray-400 hover:text-red-600 " />
            </div>
          )}
        </div>
      </aside>

      <section className="mx-auto my-5 w-full 2xl:w-[calc(100vw-350px)] flex-shrink max-w-[1366px] px-5 ">
        <div className="flex flex-col md:grid md:grid-cols-2 md:grid-rows-[auto_auto_auto_auto_1fr] ">
          {/* Product Details Header */}
          <div className=" md:col-start-2 ">
            <h1 className="  leading-none">
              <ProductTitle product={info} category={data.category} />
              <p className="text-sm "> {product.sku}</p>
            </h1>
            <span className="hidden">
              {product.checksum} {product.updatedAt} {product.url}
            </span>
          </div>
          {/* Popup with the magnified image */}
          {isMd && showImage !== false ? (
            <ImageGalleryPopup
              showImage={showImage}
              setShowImage={setShowImage}
              images={images}
              product={info}
            />
          ) : null}
          {/* Product Details Images */}
          <div className=" justify-start w-full md:row-span-5 md:row-start-1 relative">
            <div
              className="p-2 sm:p-4 cursor-zoom-in w-full aspect-square"
              onClick={() => setShowImage(images.indexOf(currentImage.slide))}
            >
              <DotSlider dots={isMd ? 1 : images.length} showArrowsMobile={true}>
                {currentImage.type === 'video' ? (
                  <YouTube
                    src={autoplayYouTubeVideoUrl(currentImage.slide.url)}
                    title={info.title}
                    className={'w-full h-full aspect-square'}
                  />
                ) : (
                  <ProductImage
                    image={currentImage.slide}
                    product={info}
                    imgStyle={{ objectFit: 'contain' }}
                    sizes={'(min-width: 768px) 50vw, 100vw'}
                    loading={'eager'}
                    tags={true}
                    isSellable={isSellable}
                    mockSizes={srcSizes}
                  />
                )}

                {isMd
                  ? null
                  : images
                      .filter((_, i) => i > 0)
                      .map(image => (
                        <ProductImage
                          key={image.url}
                          image={image}
                          product={info}
                          imgStyle={{ objectFit: 'contain' }}
                          sizes={'(min-width: 768px) 50vw, 100vw'}
                          loading={'eager'}
                          tags={true}
                          isSellable={isSellable}
                          mockSizes={srcSizes}
                        />
                      ))}
              </DotSlider>
            </div>
            <div className="relative ">
              {isMd && info.in_stock && (
                <div className="hidden md:block h-[7.25rem] lg:h-20">
                  <ImageGallerySlider
                    videos={info?.videos?.videos || []}
                    slides={images}
                    product={info}
                    onSlideChange={({ slide, type }) => setCurrentImage({ slide, type })}
                  />
                </div>
              )}

              <button
                // using the ID tangiblee needs to change from HIDDEN to FLEX className
                onClick={() =>
                  TrackEvent('TRY IT ON', {
                    'Product Title': info.title,
                    'Product URL': info.url,
                    'Product Image URL': info.small_image.url,
                    sku: info.sku,
                    price: info.price,
                    in_stock: info.in_stock,
                  })
                }
                className="hidden mt-3 mx-auto max-w-max rounded-full  pr-3 pl-1 py-1 text-sm border bg-gray-200 text-black justify-center items-center lg:absolute -top-24 right-8 hover:bg-gray-100 hover:shadow-lg hover:border-gray-300 duration-300 group"
                id="tng-btn"
              >
                <IconLoaders
                  type={product?.productType}
                  className="size-10 "
                  flashing={false}
                  iconClassName="size-full"
                />
                <span className="group-hover:text-red-700">TRY IT ON</span>
              </button>
            </div>
          </div>
          <ProductBuyingOptions
            info={info}
            isInCart={isInCart}
            addProductToCart={addProductToCart}
            isMakingAnOffer={isMakingAnOffer}
            openMakeAnOffer={openMakeAnOffer}
            openAskAQuestion={openAskAQuestion}
            openChatHandler={openChatHandler}
            cartProduct={cartProduct}
            onAffirmDetailsClick={onAffirmDetailsClick}
          />
          <div className="my-5 flex flex-col items-start mx-auto md:mx-0 text-sm xs:text-base">
            <div className="flex flex-row gap-3 text-gray-400 mx-auto md:mx-0 ">
              <a
                href="#authenticity"
                className={
                  (haveQuestions ? 'w-8 h-8 ' : 'w-12 h-24 xxs:w-16 xxs:h-24 ') +
                  ' hover:text-red-500 duration-300 text-sm leading-tight text-center'
                }
              >
                <IconAuthentic />
                {!haveQuestions && <p>Certified Authentic</p>}
              </a>
              <a
                href="#return-policy"
                className={
                  (haveQuestions ? 'w-8 h-8 ' : 'w-12 h-24 xxs:w-16 xxs:h-24 ') +
                  ' hover:text-red-500 duration-300 text-sm leading-tight text-center '
                }
              >
                <Icon10Days />
                {!haveQuestions && <p>Return Policy</p>}
              </a>
              {product.item_type === 'Watch' && (
                <div className="flex flex-row gap-2">
                  <a
                    href="#warranty"
                    className=" relative flex flex-col hover:text-red-500 duration-300 text-sm leading-tight text-center "
                  >
                    <div
                      className={
                        (haveQuestions ? 'w-8 h-8  ' : 'w-12 h-24 xxs:w-16 xxs:h-24 ') +
                        ' duration-300 relative top-0 '
                      }
                    >
                      <Icon2yrWarranty className={''} />
                      <p
                        className={
                          (haveQuestions
                            ? 'text-[.95rem] top-[11%] '
                            : 'text-[1.5rem] top-[7.5%] xxs:text-[2rem] xxs:top-[10%] ') +
                          'font-bold absolute  left-[50%] -translate-x-[50%] duration-300 '
                        }
                      >
                        {(info.warranty || '').replace(/ months?/i, '')}
                      </p>

                      {!haveQuestions && (
                        <p>
                          Service <br /> Warranty
                        </p>
                      )}
                    </div>
                  </a>

                  <a
                    href="#like-new"
                    className={
                      (haveQuestions ? 'w-8 h-8 ' : 'w-12 h-24 xxs:w-16 xxs:h-24 ') +
                      ' hover:text-red-500 duration-300 text-sm leading-tight text-center'
                    }
                  >
                    <IconYearPolish />
                    {!haveQuestions && <p>Like New For Life</p>}
                  </a>
                </div>
              )}
            </div>
            <button
              onClick={toggleHaveQuestions}
              className=" mx-auto md:mx-0 text-lg font-bold hover:text-red-700 duration-200 flex flex-row justify-start items-center gap-1 my-2"
            >
              <p className="underline underline-offset-4 decoration-red-600">Have Questions?</p>
              <div className=" text-red-700 w-5 h-5 flex justify-center items-center font-black no-underline p-1">
                <IconArrowForward
                  className={
                    (haveQuestions && 'rotate-90') +
                    ' duration-500 ease-[cubic-bezier(.75,-0.35,.5,1.23)] '
                  }
                />
              </div>
            </button>
            <div
              className={
                (haveQuestions ? ' h-[11rem] ' : 'h-[0rem] ') +
                ' mx-auto md:mx-0 flex flex-col gap-1 md:flex-row md:gap-4 mdplus:flex-col mdplus:gap-1 overflow-hidden duration-300'
              }
            >
              <a href="tel:+13058650999" className="group">
                <QuestionsPhone className="mr-2 inline h-6 w-6 rounded-full bg-red-700 p-[.25em] text-white group-hover:bg-red-600 md:scale-[150%] mdplus:scale-100" />
                <p className="inline group-hover:text-red-700 md:hidden mdplus:inline">
                  305.865.0999
                </p>
              </a>
              <button onClick={openChatHandler} className="group max-w-max">
                <QuestionsChat className="mr-2 inline h-6 w-6 rounded-full bg-red-700  p-[.25em] text-white group-hover:bg-red-600 md:scale-[150%] mdplus:scale-100" />
                <p className="inline group-hover:text-red-700 md:hidden mdplus:inline">Live Chat</p>
              </button>
              <a href="mailto:info@grayandsons.com" className="group">
                <QuestionsMail className="mr-2 inline h-6 w-6 rounded-full bg-red-700 p-[.25em] text-white group-hover:bg-red-600 md:scale-[150%] mdplus:scale-100" />
                <p className="inline group-hover:text-red-700 md:hidden mdplus:inline">
                  info@grayandsons.com
                </p>
              </a>
              <button onClick={onFAQClick} className="group flex flex-row">
                <p className="justify-center items-center font-black text-xl mr-2 inline h-6 w-6 text-center leading-6 rounded-full bg-red-700 text-white group-hover:bg-red-600 md:scale-[150%] mdplus:scale-100">
                  ?
                </p>
                <p className="inline group-hover:text-red-700 md:hidden mdplus:inline">
                  Frequently Asked Questions
                </p>
              </button>
              <a
                href="https://maps.app.goo.gl/aNtfVtZo1bYeaHbR9"
                target="_blank"
                rel="noreferrer"
                className="group grid grid-cols-[auto_1fr]"
              >
                <QuestionsLocation className="mr-2 mt-1 inline h-6 w-6 rounded-full bg-red-700 p-[.25em] text-white group-hover:bg-red-600 md:scale-[150%] mdplus:scale-100" />
                <div className="inline group-hover:text-red-700 md:hidden mdplus:inline">
                  9595 Harding Ave.,
                  <br />
                  Miami Beach, FL 33154
                  <IconExtLink />
                </div>
              </a>
            </div>
          </div>
        </div>
        {data.similarProducts && data.similarProducts.nodes.length ? (
          <RecommendedProductsSlider products={data.similarProducts.nodes} />
        ) : null}
        {/* Product Details */}
        <div className="my-2 flex flex-wrap">
          <h2 className="mb-3 w-full border-b border-gray-700 text-xl font-bold uppercase leading-loose text-gray-700">
            {info?.condition === 'Unused' ? 'Unused' : 'Pre-Owned'} {info?.brand} {info.productType}{' '}
            Details{' '}
          </h2>
          <div className="w-full py-3 leading-relaxed text-gray-700 lg:w-1/2 lg:pr-5">
            {product?.videos?.videos?.length > 0 && (
              <YouTube
                src={autoplayYouTubeVideoUrl(product.videos.videos[0].url)}
                title={info.title}
                className={'w-full aspect-square md:hidden'}
              />
            )}
            <p className="" dangerouslySetInnerHTML={{ __html: info?.description }} />
          </div>
          {/* <div className="grid w-full grid-cols-[auto_1fr] border-dotted border-gray-400 md:grid-cols-[auto_1fr_auto_1fr] lg:w-1/2 lg:border-l-2 lg:p-3"> */}
          <div className="w-full flex flex-col lg:w-1/2 lg:border-l-2 lg:p-3">
            {Object.keys(attributes).map((key, index) => (
              <div key={key} className="gap-1 ">
                <button
                  onClick={() => toggleAccordion(index)}
                  className={
                    (expandAccordion === index
                      ? 'before:bg-red-600 text-red-700 '
                      : 'before:bg-gray-300 text-gray-700 ') +
                    ' relative text-left w-full border-b border-gray-300 font-bold uppercase leading-[140%] hover:before:bg-red-700 h-8 before:content-[""] before:px-[.15rem] before:bg-gray-300 before:mr-1 duration-300 '
                  }
                >
                  {key}{' '}
                  <div className="absolute top-0 right-0 w-8 h-8  ">
                    <p
                      className={
                        (expandAccordion === index
                          ? ' rotate-0 bg-red-600 '
                          : ' rotate-90 bg-gray-600') +
                        ' absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-3 h-[.15rem] flex justify-center items-center duration-300 ease-[cubic-bezier(.75,-0.35,.5,1.23)]'
                      }
                    />
                    <p
                      className={
                        (expandAccordion === index ? '  bg-red-600 ' : '  bg-gray-600 ') +
                        'absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-3 h-[.15rem] flex justify-center items-center bg-gray-600 duration-300 ease-[cubic-bezier(.75,-0.35,.5,1.23)]'
                      }
                    />
                  </div>
                </button>
                {attributes[key].map((attribute, i) => {
                  return (
                    <div
                      className={
                        (expandAccordion === index ? 'opacity-100 delay-150  ' : ' opacity-0 ') +
                        '  ml-3 odd:bg-gray-100/70 grid grid-cols-2 items-center gap-1 overflow-hidden duration-300 '
                      }
                      style={{
                        height:
                          expandAccordion === index
                            ? `${
                                (getValue(attribute, info[attribute]) || '').toString().split('\n')
                                  .length * 2
                              }rem`
                            : '0rem',
                      }}
                      key={i}
                    >
                      {getLabel(attribute)}
                      <strong className="whitespace-pre-line">
                        {getValue(attribute, info[attribute])}
                      </strong>
                    </div>
                  )
                })}
              </div>
            ))}
            {product.productSizeGuide && (
              <FindYourSize category={product.productSizeGuide} onClick={onFindYourSizeClick} />
            )}
          </div>
        </div>
        {sizeGuide && (
          <>
            <a ref={sizeGuideDetails} />
            <SizeGuideComponent
              sizeGuide={sizeGuide}
              product={product}
              fallback={<div className="h-full">Loading...</div>}
            />
          </>
        )}
        {/*<SimilarItemsCarousel products={data.category?.products} />*/}
        <h2 className=" underline-title my-4 2xl:hidden">HAVE QUESTIONS?</h2>
        <div className="flex w-full flex-row flex-wrap justify-around 2xl:hidden">
          {keithSideCard ? (
            <KeithSideCard />
          ) : (
            <>
              <ViktoriaSideCard />
              <RichSideCard product={info} />
            </>
          )}
        </div>
        {viewAffirm && (
          <>
            <a ref={AffirmRef} />
            <AffirmFinancing fallback={<div className="h-screen">Loading...</div>} />
          </>
        )}
        {viewFAQ && (
          <>
            <a ref={FAQRef} />
            <FAQComponent fallback={<div className="h-full">Loading...</div>} />
          </>
        )}
        <WhyGnS
          productType={product.item_type}
          fallback={<div className="h-full">Loading...</div>}
        />
      </section>

      <div className="w-10/12 max-w-[1366px] mx-auto py-10 hidden">
        Updated At: {product.updatedAt}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($strapiId: Int!, $categoryId: Int, $similarProduct: [Int!]) {
    similarProducts: allStrapiProduct(
      filter: { strapi_id: { in: $similarProduct } }
      sort: { fields: stock_date, order: DESC }
    ) {
      nodes {
        name
        productType
        categories {
          name
          url: urlPath
          strapi_id
        }
        url: urlPath
        sku
        title
        qty
        small_image {
          gatsbyImageDataMock
        }
        brand
        model
        price
        special_price
      }
    }

    item: strapiProduct(strapi_id: { eq: $strapiId }) {
      url: urlPath
      checksum
      price
      name
      authenticity
      band_metal
      metal_name {
        metals: strapi_json_value
      }
      description
      age
      ask_for_price
      band_color
      band_length
      band_size
      band_type
      band_style
      band_width
      bezel_material
      booklets
      brand
      buckle_material
      buckle_style
      case_back
      case_length
      case_material
      case_shape
      case_size
      case_size_mm
      case_thickness
      condition
      dial_style
      dial: dial_color_attribute
      diamond_weight
      diamond_weight_attribute
      diamonds
      price_3rd
      title
      features {
        values: strapi_json_value
      }
      gender
      gender_mfu
      head_metal
      certificates {
        name
        gatsbyImageDataMock
        url
        width
        height
        ext
      }
      image {
        name
        gatsbyImageDataMock
        url
        width
        height
        ext
      }
      videos {
        videos {
          url
          title
          thumbnail {
            gatsbyImageDataMock
          }
        }
      }
      item_type
      item_weight
      in_stock
      width
      item_category
      jewelry_type
      lug_to_lug_length
      manufacturer
      media_gallery {
        name
        gatsbyImageDataMock
        url
        width
        height
        ext
      }
      metals {
        values: strapi_json_value
      }
      stones {
        values: strapi_json_value {
          # weight_unit
          weight
          type
          total_weight
          cut
          color
          clarity
          # certification
        }
      }
      model
      model_no
      movement
      on_hold
      original_box
      original_papers
      price_filter
      qty
      retail_price
      search_terms
      serial_number
      short_name
      sku
      special_price
      small_image {
        name
        gatsbyImageDataMock
        url
        width
        height
        ext
      }
      thumbnail {
        name
        gatsbyImageDataMock
        url
        width
        height
        ext
      }
      warranty
      size
      watch_style
      productType
      productSizeGuide
      sizeGuide: productSizeGuidePage {
        sizeGuideSections: sections {
          ... on STRAPI__COMPONENT_SECTION_PARAGRAPH {
            ...ParagraphSection
          }
        }
      }
      categories {
        name
        url: urlPath
        strapi_id
      }
      # ago: stock_date(fromNow: true)
      stock_date(formatString: "MMMM DD, YYYY")
      seo {
        ...SEO
      }
      updatedAt
    }
    category: strapiCategory(strapi_id: { eq: $categoryId }) {
      name: singularName
      breadcrumbs {
        name
        url: urlPath
      }
    }
  }
`

ProductDetailsPage.propTypes = {
  data: PropTypes.shape({
    similarProducts: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          productType: PropTypes.string.isRequired,
          categories: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              url: PropTypes.string.isRequired,
              strapi_id: PropTypes.string.isRequired,
            })
          ).isRequired,
          url: PropTypes.string.isRequired,
          sku: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          qty: PropTypes.number.isRequired,
          small_image: PropTypes.shape({
            gatsbyImageDataMock: PropTypes.object.isRequired,
          }).isRequired,
          brand: PropTypes.string.isRequired,
          model: PropTypes.string.isRequired,
          price: PropTypes.number.isRequired,
          special_price: PropTypes.number,
        })
      ).isRequired,
    }).isRequired,
    item: PropTypes.shape({
      isSellable: PropTypes.bool,
      checksum: PropTypes.string,
      title: PropTypes.string.isRequired,
      short_name: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      retail_price: PropTypes.number,
      special_price: PropTypes.number,
      description: PropTypes.string.isRequired,
      productType: PropTypes.string.isRequired,
      ask_for_price: PropTypes.number.isRequired,
      qty: PropTypes.number.isRequired,
      dial_color: PropTypes.string,
      brand: PropTypes.string,
      model: PropTypes.string,
      model_no: PropTypes.string,
      case_size_mm: PropTypes.number,
      case_material: PropTypes.string,
      url: PropTypes.string,
      sku: PropTypes.string,
      item_type: PropTypes.string,
      item_category: PropTypes.string,
      in_stock: PropTypes.bool,
      productSizeGuide: PropTypes.string,
      updatedAt: PropTypes.string,
      sizeGuide: PropTypes.object,
      warranty: PropTypes.string,
      condition: PropTypes.string,
      small_image: PropTypes.shape({
        name: PropTypes.string.isRequired,
        gatsbyImageDataMock: PropTypes.object.isRequired,
        url: PropTypes.string,
        ext: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }).isRequired,
      thumbnail: PropTypes.shape({
        name: PropTypes.string.isRequired,
        gatsbyImageDataMock: PropTypes.object.isRequired,
        url: PropTypes.string,
        ext: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }).isRequired,
      image: PropTypes.shape({
        name: PropTypes.string.isRequired,
        gatsbyImageDataMock: PropTypes.object.isRequired,
        url: PropTypes.string,
        ext: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }).isRequired,
      certificates: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          gatsbyImageDataMock: PropTypes.object,
        })
      ),
      media_gallery: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          gatsbyImageDataMock: PropTypes.object.isRequired,
        })
      ).isRequired,
      videos: PropTypes.shape({
        videos: PropTypes.arrayOf(
          PropTypes.shape({
            url: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            thumbnail: PropTypes.shape({
              gatsbyImageDataMock: PropTypes.object.isRequired,
            }),
          })
        ),
      }),
      seo: Seo.propTypes.seo,
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    category: PropTypes.shape({
      name: PropTypes.string.isRequired,
      breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
  }).isRequired,
}

export default ProductDetailsPage
